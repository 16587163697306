// Abstract
@import "./abstracts/mixins";
@import "./abstracts/tokens";
@import "./abstracts/variables";
@import "./abstracts/fonts";

//Base
@import "./base/reset";

.video-container {
  margin: auto;
  position: relative;
  padding-top: 56.25%;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  & video {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.toast {
  .bold {
    font-weight: 700;
  }
}

.chq-atc {
  display: inline-block;
  position: relative;
}

.chq-atc--button {
  background: transparent;
  border: 1px solid #000 !important;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #000 !important;
  cursor: pointer;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 12px 32px !important;

  & svg {
    color: #000 !important;
    display: none;

    path {
      fill: #000 !important;
    }
  }
}

.chq-atc--dropdown {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  box-shadow: 0.2px 0.4px 0.5px rgb(161 161 161 / 36%),
    0.5px 1.3px 1.6px -0.8px rgb(161 161 161 / 36%),
    1.3px 3.4px 4.1px -1.7px rgb(161 161 161 / 36%),
    3.1px 8.2px 9.9px -2.5px rgb(161 161 161 / 36%) !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  z-index: 1;

  a {
    color: #000 !important;
  }
}

.react-tel-input {
  font-family: $Tiempos !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fafafa !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 2px 0 0 2px !important;
}

.react-tel-input .form-control {
  background-color: #fafafa !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 2px !important;
  color: #000 !important;
}

.swiper-pagination-bullet {
  width: 38.08px !important;
  height: 4.23px !important;
  background: rgba(43, 44, 43, 0.42) !important;
  border-radius: 84.6154px !important;

  .swiper-pagination-bullet-active {
    background: #000000 !important;
  }
}

div[data-radix-portal] {
  z-index: 2147483646 !important;
}
