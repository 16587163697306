//Autography
@font-face {
  font-family: "Autography";
  src: url("../../assets/fonts/Autography/Autography.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

//CreattionDemo
@font-face {
  font-family: "CreattionDemo";
  src: url("../../assets/fonts/Creattion-Demo/CreattionDemo.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

// Old English
@font-face {
  font-family: "Old English Text MT";
  src: url("../../assets/fonts/Old-English/oldEnglish.ttf");
  font-weight: 400;
  font-style: normal;
}

// Nue
@font-face {
  font-family: "Neue Plak Wide";
  src: url("../../assets/fonts/Neue/NeuePlak-WideRegular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Wide";
  src: url("../../assets/fonts/Neue/NeuePlak-WideBold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Lovera
@font-face {
  font-family: "Lovera";
  src: url("../../assets/fonts/Lovera/lovera.otf");
  font-weight: 400;
  font-style: normal;
}

//Canela
@font-face {
  font-family: "Canela";
  src: local("Canela Thin"), local("Canela-Thin");
  src: url("../../assets/fonts/Canela/Canela-Thin\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Thin Italic"), local("Canela-ThinItalic");
  src: url("../../assets/fonts/Canela/Canela-ThinItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Light"), local("Canela-Light");
  src: url("../../assets/fonts/Canela/Canela-Light\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Light Italic"), local("Canela-LightItalic");
  src: url("../../assets/fonts/Canela/Canela-LightItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Regular"), local("Canela-Regular");
  src: url("../../assets/fonts/Canela/Canela-Regular\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Regular Italic"), local("Canela-RegularItalic");
  src: url("../../assets/fonts/Canela/Canela-RegularItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Medium"), local("Canela-Medium");
  src: url("../../assets/fonts/Canela/Canela-Medium\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela MediumItalic"), local("Canela-MediumItalic");
  src: url("../../assets/fonts/Canela/Canela-MediumItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Bold"), local("Canela-Bold");
  src: url("../../assets/fonts/Canela/Canela-Bold\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela BoldItalic"), local("Canela-BoldItalic");
  src: url("../../assets/fonts/Canela/Canela-BoldItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Bold"), local("Canela-Bold");
  src: url("../../assets/fonts/Canela/Canela-Bold\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela BoldItalic"), local("Canela-BoldItalic");
  src: url("../../assets/fonts/Canela/Canela-BoldItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela Black"), local("Canela-Black");
  src: url("../../assets/fonts/Canela/Canela-Black\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Canela";
  src: local("Canela BlackItalic"), local("Canela-BlackItalic");
  src: url("../../assets/fonts/Canela/Canela-BlackItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 900;
  font-style: italic;
}

//Tiempos

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Light"), local("Tiempos Headline-Light");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Light.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Light Italic"),
    local("Tiempos Headline-LightItalic");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-LightItalic.otf")
    format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Regular"), local("Tiempos Headline-Regular");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Regular Italic"),
    local("Tiempos Headline-RegularItalic");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-RegularItalic.otf")
    format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Medium"), local("Tiempos Headline-Medium");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos MediumItalic"), local("Tiempos Headline-MediumItalic");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-MediumItalic.otf")
    format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline SemiBold"), local("Tiempos Headline-SemiBold");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Semibold.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline SemiBoldItalic"),
    local("Tiempos Headline-SemiBoldItalic");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-SemiboldItalic.otf")
    format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Bold"), local("Tiempos Headline-Bold");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Bold.otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline BoldItalic"),
    local("Tiempos Headline-BoldItalic");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-BoldItalic.otf")
    format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Canela";
  src: local("Canela BoldItalic"), local("Canela-BoldItalic");
  src: url("../../assets/fonts/Canela/Canela-BoldItalic\ [TheFontsMaster.com].otf")
    format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline Black"), local("Tiempos Headline-Black");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-Black.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline BlackItalic"),
    local("Tiempos Headline-BlackItalic");
  src: url("../../assets/fonts/Tiempos-Font/TiemposHeadline-BlackItalic.otf")
    format("opentype");
  font-weight: 900;
  font-style: italic;
}
