@import "../abstracts/variables";

:root {
  font-size: 16px;
}

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
  // Prevent Bluee highlighting of element on multi browser
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scrollbar-width: 8px;

  &:focus {
    outline: none !important;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: $col-secondary-9;
  border-radius: 20px;
  margin-right: 2px;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
  margin: 0;
  direction: rtl;
  /* update text-align value to right;  on latin screens*/
  direction: ltr;
  /* update text-align value to left; on latin screens*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font;
  scroll-behavior: smooth;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

a {
  background-color: transparent;
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  display: block;
  border: none;
  outline: none;
  max-width: 100%;
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  /* 2 */
  outline: none;
}

input,
button,
textarea,
select {
  font-family: inherit;
}

button,
input {
  /* 1 */
  overflow: visible;
}

input,
input:before,
input:after {
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  -webkit-border-radius: initial;
  border-radius: 0;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"],
[type="date"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */

  &::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

p {
  font-family: $font;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  font-family: $font;
}

/* Remove list styles on ul, ol */
ul,
ol,
li {
  list-style: none;
  padding: 0;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: $col-black;
}

button:focus,
button:hover {
  outline: none;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
